import React from 'react';
import styled from 'styled-components';

import MainContent from "../MainContent/MainContent";
import LazyLoadModule from '../LazyLoadModule/LazyLoadModule';
import CamelcaseToTitle from "../../helpers/TextHelper/CamelcaseToTitle";

import FBTheme from "../../../variables/theme/theme"
import Text from "../../Elements/Text/Text"

const ComponentPreview = styled.div`
  background-color: ${FBTheme.palette.grey[200]};
  border-radius: ${FBTheme.shape.borderRadius}px;
  padding-top: 48px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 24px;
`

export default (props) => {
  const path = props.path || "Components";
  const title = CamelcaseToTitle(props.name);

  return <MainContent>
    <Text hero>{title}</Text>
    <ComponentPreview>
      <LazyLoadModule
        key={props.name}
        name={props.name}
        resolve={/* () => {} */() => import(`../../${path}/${props.name}/${props.name}.demo.js`)}
      />
    </ComponentPreview>
  </MainContent>
}