import React from 'react';

import LazyLoadModule from '../react/helpers/LazyLoadModule/LazyLoadModule';

export default (props) => {
    return <LazyLoadModule
        path={props.path}
        key={props.name}
        name={props.name}
        resolve={() => import(`./src/${props.path}/views/${props.name}/${props.name}`)}
    />
  }