import FBTheme from "../../../variables/theme/theme"
import styled from 'styled-components';

const MainContent = styled.div`
    &&{
        margin-left: ${FBTheme.sizes.responsiveNavBar}px;
        margin-bottom: 100px;
        max-width: calc(100% - ${FBTheme.sizes.responsiveNavBar}px);
        padding-left: 40px;
        padding-right: 72px;
        padding-top: 96px;

        @media (max-width: ${FBTheme.breakpoints.values["md"]}px) {
            padding-left: 24px;
            padding-right: 40px;
            margin-left: 0;
            max-width: 100%;
          },     
    }
`;

export default MainContent;