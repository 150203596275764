/**
* @render react
* @name Button
* @example
* <Button />
*   
*/

import React from 'react';
import { Button as MaterialButton } from '@material-ui/core';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import FBTheme from "../../../variables/theme/theme";
import PropTypes from "prop-types";
import { fade } from "@material-ui/core/styles/colorManipulator"
import styled from "styled-components"

const mediaHoverNone = `@media (hover: none) {
  background-color: 'transparent';
}`

const StyledButton = styled(MaterialButton)`
  && {
  ${props => ({variant, tone}) => {
    if(tone) {
      if(variant === undefined || variant === "text") {
        return `
        color: ${FBTheme.palette[tone].main};
        &:hover {
          background-color: ${fade(FBTheme.palette[tone].main, FBTheme.palette.action.hoverOpacity)};
          ${mediaHoverNone}
        }`;
      } else if (variant === "outlined") {
        return `
        color: ${FBTheme.palette[tone].main};
        border-color: ${fade(FBTheme.palette[tone].main, 0.5)};
        &:hover {
          border-color: ${FBTheme.palette[tone].main};
          background-color: ${fade(FBTheme.palette[tone].main, FBTheme.palette.action.hoverOpacity)};
          ${mediaHoverNone}
        }
        &:disabled {
          border-color: ${FBTheme.palette.action.disabled};
        }
        `
      } else if (variant === "contained") {
        return `
        background-color: ${FBTheme.palette[tone].main};
        color: ${FBTheme.palette[tone].contrastText};
        &:hover {
          background-color: ${FBTheme.palette[tone].dark};
          @media (hover: none) {
            ${FBTheme.palette[tone].main};
          }
        }
        `
      }
    }
  }}
}
`

class Button extends MaterialButton {
  render() {
    const { tone, ...restProps } = this.props;
    
    if(["default", "inherit", "primary", "secondary"].indexOf(tone) !== -1) {
      // compatability with material ui button component
      restProps.color = tone;
    } else {
      restProps.tone = tone;
    }

    return (
      <MuiThemeProvider theme={FBTheme}>
        <StyledButton {...restProps}>{this.props.children}</StyledButton>
      </MuiThemeProvider>
    )
  }
}

Button.propTypes = {
  tone: PropTypes.oneOf(['default', 'primary', 'secondary', 'positive', 'warning', 'critical']),
}

Button.defaultProps = {
  children: "Button",
  tone: "default"
}

export default Button;