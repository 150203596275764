// React Libs
import React, { Component } from 'react';
import { Route, Switch } from "react-router-dom"

// Docs
import StyleGuide from './src/styleguide/styleguide';

// Prototypes
import Prototypes from "../prototypes";
import PrototypeDetailPage from "../prototypes/PrototypeDetailPage";
import PrototypeNavItems from "../react/helpers/_prototypes.json"

// Material UI Components
import CssBaseline from '@material-ui/core/CssBaseline';

// Styles
import './App.css';
import "../variables/fonts/fonts.css";

//Material UI Migration Config to typography v2
window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;

class App extends Component {
  render() {
    return (
      <React.Fragment>
        <CssBaseline />
        <Switch>
          <Route exact path="/prototypes" component={Prototypes} />

          {
            PrototypeNavItems.children.map(i => {
              return i.children.map(j => {
                if (j.name === "views" && j.children.length) {
                  return j.children.map((k, index) => {
                    const routeName = k.name.replace(k.extension, "");
                    return <Route
                      exact
                      path={`/${i.name}/${routeName}`}
                      render={({ match }) => {
                        return <PrototypeDetailPage key={index} path={i.name} name={k.name} />
                      }}
                    />
                  })
                }
                return false;
              })
            })
          }

          <Route component={StyleGuide} />
        </Switch>
      </React.Fragment>
    );
  }
}

export default App;
