/**
* @render react
* @name NavBar
* @example
* <NavBar />
*   
*/

import React, { Component } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import PersonIcon from '@material-ui/icons/Person';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import styled from 'styled-components';
import Grow from '@material-ui/core/Grow';

import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import FBTheme from '../../../variables/theme/theme';

const FBAppBar = styled(AppBar)`
    background:radial-gradient(ellipse at top, transparent 0%,${FBTheme.palette.primary.dark} 60%), url(${props => props.bgimg});
    background-size:100% auto;
    background-position: center;
    position:relative;
    @media (min-width: ${FBTheme.breakpoints.values.sm}px) {
        padding: ${props => props.padding || 0} 0;
    }
`;

const Spacer = styled.div`
    flex-grow:1;
`;

const Search = styled.div`
        position:absolute;
        top:0;
        width:100%;
        height:100%;
        svg{
            position:absolute;
            right:0;
            transform:translateY(-50%);
            top:50%;
            margin-right:1rem;
        }
`;

const FBInputBase = styled(InputBase)`
        &&{
            padding-left:1rem;
            width:100%;
            height:100%;
            font-size:1rem;
            color:white;
            background:${FBTheme.palette.primary.dark}
            @media (min-width: ${FBTheme.breakpoints.values.sm}px) {
                font-size:2rem;}
        }
`;

const FBIconButton = styled(IconButton)`
        &&{
            color:#fff
        }
`

const Logo = styled.img`
    height: auto;
    width: 32px;
    @media(min-width: ${ FBTheme.breakpoints.values.sm}px) {
        height: 48px;
        width: auto;
        max-width: 173px;
}`

class NavBar extends Component {
    state = {
        showSearch: false,
        showMenu: false
    }

    toggleSearch = () => {
        this.setState({ showSearch: !this.state.showSearch })
    }

    toggleMenu = () => {
        this.setState({ showMenu: !this.state.showMenu })
    }
    render() {
        return (
            <MuiThemeProvider theme={FBTheme}>
                <FBAppBar position="relative" padding={this.props.padding} bgimg={this.props.bgimg}>
                    <Toolbar>
                        {this.props.logo && <Logo height={48} src={this.props.logo} />}
                        <Spacer />
                        <FBIconButton onClick={this.toggleSearch}>
                            <SearchIcon />
                        </FBIconButton>
                        <FBIconButton onClick={this.props.loginHandler}>
                            <PersonIcon />
                        </FBIconButton>
                    </Toolbar>
                    {this.state.showSearch ?
                        <Search>
                            <ClearIcon onClick={this.toggleSearch} />
                            <FBInputBase placeholder="Search..." />
                        </Search> : ""}
                </FBAppBar>
                {
                    // this.state.showMenu && this.props.menu
                    <Grow direction="down" in={this.state.showMenu} mountOnEnter unmountOnExit>
                        {this.props.menu}
                    </Grow>
                }
            </MuiThemeProvider>
        );
    }
}

NavBar.defaultProps = {
    logo: "https://s3-ap-southeast-2.amazonaws.com/formbay-static/logo_white.png"
};

export default NavBar;
