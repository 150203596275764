import React from "react";
import PropTypes from "prop-types";
import withTextProps, { SizePropTypes } from "../../helpers/withTextProps/withTextProps"
import styled from "styled-components";

import FBTheme from "../../../variables/theme/theme";
import Type from "../../../variables/type/type";

// @manuel
// TODO
// - use styled-component variants https://github.com/styled-components/styled-theming#themevariantsname-prop-themes

class Text extends React.Component {
    render() {
        const {
            component,
            children,
            className,
            style
        } = this.props;

        const Component = component || "span";
        return (
            <Component className={className} style={style}>
                <span>
                    {children}
                </span>
            </Component>
        )
    }
}

Text.propTypes = {
    component: PropTypes.oneOfType([
      PropTypes.element,
      PropTypes.string,
      PropTypes.func
    ]),
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    positive: PropTypes.bool,
    critical: PropTypes.bool,
    secondary: PropTypes.bool,
    info: PropTypes.bool,
    strong: PropTypes.bool,
    regular: PropTypes.bool,
    light: PropTypes.bool,
    disabled: PropTypes.bool,
    padding: PropTypes.bool,
    block: PropTypes.bool,
    ...SizePropTypes,
  };

Text.defaultProps = {
    regular: true
}

// TODO test this function ...
// Generates css (font size, font weight) for different breakpoints specified in "../../../variables/type/type"
const fontSizePerBreakpointProvider = ( size = "standard" ) => {
    const mappedSize = Type[size];
    let fontSizes = ``;
    Object.entries(mappedSize.breakpoints).forEach(([key, breakpoint]) => {
        fontSizes += `
        ${FBTheme.breakpoints.up(key)} {
            font-size: ${breakpoint.fontSize}px;
            font-family: ${breakpoint.fontFamily};
        }
        `
    })

    return fontSizes;
}

// TODO
// - support themes (dark|light)

const StyledText = styled(Text)`
    ${props => fontSizePerBreakpointProvider(props.size)};

    color: ${props => {
        if(props.info) return FBTheme.palette.text.secondary;
        else if(props.disabled) return FBTheme.palette.text.disabled;
        else if(props.critical) return FBTheme.palette.text.critical;
        else if(props.positive) return FBTheme.palette.text.positive;
        else if(props.secondary) return FBTheme.palette.secondary.main;
        else return FBTheme.palette.text.primary;
    }};

    display: ${props => props.block ? "block" : "inline-block"};
    ${props => props.padding ? `padding-bottom: ${Type[props.size].paddingBottom}px` : "padding-bottom: 0;"};
    ${props => {
        return `line-height: ${Type[props.size].lineHeight}px`;
    }};

    && {
        font-family: ${props => {
            if(props.strong) return FBTheme.typography.fontFamilyMedium;
            else if(props.light) return FBTheme.typography.fontFamilyLight;
        }}
    }
`

StyledText.defaultProps = {
    size: "standard",
    padding: true,
    block: false
}

export default withTextProps(StyledText);