import React from 'react';
import PropTypes from 'prop-types'
import styled from "styled-components";

import { TextField as MaterialTextField } from '@material-ui/core';

import FBTheme from "../../../variables/theme/theme";
import Type from "../../../variables/type/type";

// TODO @manuel
// - fix sizes

class TextField extends MaterialTextField {
    render() {
        const {
            small,
            ...props
        } = this.props
        
        return (
            <MaterialTextField
                autoComplete="none"
                {...props}
            />
        )
    }
}

TextField.defaultProps = {
    variant: "outlined",
}

TextField.props = {
    small: PropTypes.bool
}

export default styled(TextField)`
&& {
    ${props => ({ small }) => {
    if (small) return `
            & .textarea,
            input {
                min-height: 21px;
                font-size: ${Type.small.fontSize};
                padding: ${FBTheme.spacing.unit * 1.75}px ${FBTheme.spacing.unit * 1.25}px;
            }
        `
    }}
}
`;