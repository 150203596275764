import React from 'react';
import mapKeys from 'lodash/mapKeys';
import Text from './Text';


import Type from "../../../variables/type/type";

const breakpointMap = {
  "xs": "Mobile",
  "sm": "Mobile (Large)",
  "md": "Tablet",
  "lg": "Desktop",
  "xl": "Desktop (Wide)",
}

const symbolNames = Object.keys(Type).reduce((obj, size, i) => {
  const [firstChar, ...restChars] = size;

  const name = `${firstChar.toUpperCase()}${restChars.join('')}`;

  let breakpointFontSizes = "";
  Object.entries(Type[size].breakpoints).forEach(([key, breakpoint]) => {
    const val = `${breakpointMap[key]}: ${breakpoint.fontSize}px`;
    if (breakpointFontSizes !== "") breakpointFontSizes += ", " + val;
    else breakpointFontSizes += val;
  });

  const symbolName = `${i + 1}. ${name} — ${breakpointFontSizes}`;

  return { ...obj, [size]: symbolName };
}, {});

export const text = {
  [`${symbolNames.hero}`]: <Text hero>Hero text</Text>,
  [`${symbolNames.headline}`]: <Text headline>Headline text</Text>,
  [`${symbolNames.heading}`]: <Text heading>Heading text</Text>,
  [`${symbolNames.subheading}`]: <Text subheading>Subheading text</Text>,
  [`${symbolNames.large}`]: <Text large>Large text</Text>,
  [`${symbolNames.standard}/1. Default`]: <Text>Standard text</Text>,
  [`${symbolNames.standard}/2. Info`]: <Text info>Standard info text</Text>,
  [`${symbolNames.standard}/3. Disabled`]: <Text disabled>Standard disabled text</Text>,
  [`${symbolNames.standard}/4. Secondary`]: <Text secondary>Standard critical text</Text>,
  [`${symbolNames.standard}/5. Strong`]: <Text strong>Standard strong text</Text>,
  [`${symbolNames.standard}/6. Light`]: <Text light>Standard light text</Text>,
  [`${symbolNames.standard}/7. Positive`]: <Text positive>Standard positive text</Text>,
  [`${symbolNames.standard}/8. Critical`]: <Text critical>Standard critical text</Text>,
  [`${symbolNames.small}`]: <Text small>Small text</Text>,
  [`${symbolNames.tiny}`]: <Text tiny>Tiny text</Text>
};

// Export text styles as symbols
// (at least until Sketch libraries support shared text styles)
export const symbols = mapKeys(text, (value, key) => `Text/${key}`);