// React Libs
import React, { Component } from 'react';
import styled from 'styled-components';

// Material UI Components
import { Typography, Avatar } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import FolderIcon from '@material-ui/icons/Folder';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Dialog from '@material-ui/core/Dialog';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Slide from '@material-ui/core/Slide';

// Formbay Components & Elements
import DatePicker from "../../../react/Components/DatePicker/DatePicker";

import Grid from '../../../react/Elements/Grid/Grid';
import TextField from "../../../react/Elements/TextField/TextField";
import Button from "../../../react/Elements/Button/Button";

import MainContent from "../../../react/helpers/MainContent/MainContent";

import FBTheme from '../../../variables/theme/theme';

const FBTypography = styled(Typography)`
&&{
    color:${FBTheme.palette.primary.dark};
    margin-bottom:1rem;
    margin-top:1rem;
    &:nth-child(1){
        margin-top:0;
    }
}
`
const FBAvatar = styled(Avatar)`
&&{
    color:${FBTheme.palette.primary.dark}
}`

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

class ComponentsList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dialogOpen: false
        }
        this.openDialog = () => { this.setState({ dialogOpen: true }) }
        this.closeDialog = () => { this.setState({ dialogOpen: false }) }
    }

    render() {
        return (
            <React.Fragment>
                <MainContent>
                    <FBTypography variant="h5">Inputs</FBTypography>
                    <Grid col="3">
                        <FormControl>
                            <TextField
                                id="date"
                                label="DOB"
                                type="date"
                                defaultValue="2017-05-24"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </FormControl>
                        <FormControl>
                            <TextField
                                id="datetime-local"
                                label="Next appointment"
                                type="datetime-local"
                                defaultValue="2017-05-24T10:30"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </FormControl>

                        <FormControl>
                            <InputLabel htmlFor="age-helper">Age</InputLabel>
                            <Select
                                value={"age"}
                                input={<Input name="age" id="age-helper" />}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                <MenuItem value={10}>Ten</MenuItem>
                                <MenuItem value={20}>Twenty</MenuItem>
                                <MenuItem value={30}>Thirty</MenuItem>
                            </Select>
                            <FormHelperText>Some important helper text</FormHelperText>
                        </FormControl>
                    </Grid>

                    <FBTypography variant="h5">Avatars</FBTypography>
                    <FBAvatar>
                        <FolderIcon />
                    </FBAvatar>

                    <FBTypography variant="h5">Dialog</FBTypography>
                    <Button onClick={this.openDialog}>Open Dialog</Button>

                    <FBTypography variant="h5">Date &amp; Time</FBTypography>
                    <DatePicker showTodayButton variant="outlined" label="Simple Datepicker" />

                </MainContent>

                <Dialog
                    open={this.state.dialogOpen}
                    TransitionComponent={Transition}

                >
                    <AppBar>
                        <Toolbar>
                            <IconButton color="inherit" onClick={this.closeDialog} aria-label="Close">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" color="inherit">
                                Serial Numbers
                    </Typography>

                        </Toolbar>
                    </AppBar>
                    <List>
                        <ListItem button>
                            <ListItemText primary="Panel 1" secondary="ifdoisuafodsoaiufdso" />
                        </ListItem>
                        <Divider />
                        <ListItem button>
                            <ListItemText primary="Panel 2" secondary="9878978790879089709" />
                        </ListItem>
                    </List>
                </Dialog>

            </React.Fragment>
        );
    }
}

export default ComponentsList;