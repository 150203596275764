/**
* @render react
* @name Link
* @example
* <Link>Test Link</Link>
*   
*/

import React from 'react';
import PropTypes from "prop-types";
import {Link as ReactLink} from "react-router-dom";
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import styled from "styled-components";
import FBTheme from "../../../variables/theme/theme";

// @manuel
// - provide underline prop bool to add underline on hover

const LinkElement = (props) => {
    const isExternal = props.to && props.to.match("http(s)?://");

    return isExternal 
    ? <a className={props.className} href={props.to} target="_blank" rel="noopener noreferrer">{props.children}</a> 
    : <ReactLink {...props}>{props.children}</ReactLink>
}

class Link extends React.Component {
    render() {
        const {
            underline,
            secondary,
            ...props
        } = this.props;

        return (
            <MuiThemeProvider theme={FBTheme}>
                <LinkElement {...props} />
            </MuiThemeProvider>
        )
    }
}

Link.defaultProps = {
    to: "/"
}

Link.propTypes = {
    underline: PropTypes.bool,
    secondary: PropTypes.bool
}

const StyledLink = styled(Link)`
&& {
    color: ${props => {
        if(props.secondary) return FBTheme.palette.secondary.main;
        else return FBTheme.palette.primary.main;
    }};
    font-family: ${FBTheme.typography.fontFamilyMedium};
    text-decoration: none;

    ${props => {
        if(props.underline) {
            return `
            &:hover {
                text-decoration: underline;
            }
            `
        }
    }}
}
`

export default StyledLink;