import React from 'react';
import PropTypes from 'prop-types';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import styled from 'styled-components';
import { withStyles } from "@material-ui/core/styles";
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';

import FBTheme from '../../../variables/theme/theme';
import Text from "../../Elements/Text/Text";

const drawerWidth = 240;

const StyledDrawer = styled.nav`
  min-height: 460px;
  position: relative;
  ${[FBTheme.breakpoints.up('sm')]} {
    width: ${drawerWidth};
    flex-shrink: 0;
  },
`

const StyledToolbar = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 24px;
`

const MenuImage = styled.img`
    width: 48px;
`

const MenuTitle = styled(Text)`
    padding-left: 16px;
`

const styles = {
  drawerPaper: {
    position: "absolute",
    width: drawerWidth
  }
}

class ResponsiveDrawer extends React.Component {
    constructor(props) {
        super(props);
        this.state = { mobileOpen: this.props.mobileOpen };
    }

  render() {
    const { classes } = this.props;
    const mobileOpen = this.props.mobileOpen;

    const drawer = (
      <div>
        <StyledToolbar>
            <MenuImage src={this.props.menuImage} />
            <MenuTitle padding={false} subheading>{this.props.menuTitle}</MenuTitle>
        </StyledToolbar>
        <Divider />
        <List>
          {this.props.menuItems.map((text, index) => (
            <ListItem button key={text}>
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List>
        <Divider />
        <List>
          {this.props.secondaryMenuItems.map((text, index) => (
            <ListItem button key={text}>
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List>
      </div>
    );

    return (
      <MuiThemeProvider theme={FBTheme}>
        <StyledDrawer>
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Hidden smUp implementation="css">
            <Drawer
              container={this.props.container}
              variant="temporary"
              open={mobileOpen}
              onClose={this.handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              {drawer}
            </Drawer>
          </Hidden>
          <Hidden xsDown implementation="css">
            <Drawer
              variant="permanent"
              classes={{
                paper: classes.drawerPaper
              }}
              open
            >
              {drawer}
            </Drawer>
          </Hidden>
        </StyledDrawer>
      </MuiThemeProvider>
    );
  }
}

ResponsiveDrawer.defaultProps = {
    menuItems: ["Item 1", "Item 2", "Item 3"],
    secondaryMenuItems: ["Item 1", "Item 2", "Item 3"],
    menuTitle: "Example Drawer",
}

ResponsiveDrawer.propTypes = {
  menuItems: PropTypes.array.isRequired,
  secondaryMenuItems: PropTypes.array,
  menuTitle: PropTypes.string,
  menuImage: PropTypes.string
};

export default withStyles(styles)(ResponsiveDrawer);