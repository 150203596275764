import React, { Component } from 'react';
import styled from "styled-components"

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import ListItem from '@material-ui/core/ListItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt"

import ResponsiveNavBar from "../react/Components/ResponsiveNavBar/ResponsiveNavBar";
import MainContent from "../react/helpers/MainContent/MainContent";
import Text from "../react/Elements/Text/Text";
import Grid from "../react/Elements/Grid/Grid";

import ComponentNavItems from "../react/helpers/_components.json";
import ElementNavItems from "../react/helpers/_elements.json";
import PrototypeNavItems from "../react/helpers/_prototypes.json"

import Capitalize from "../react/helpers/TextHelper/Capitalize"
import Camelcase from "../react/helpers/TextHelper/CamelcaseToTitle"

import FBTheme from "../variables/theme/theme"

const viewsPath = "views";
const PrototypeCard = styled(props => {
    return (
        <Grid item xs={4}>
            <Card className={props.className}>
                <CardHeader title={Capitalize(props.name)} />
                <CardContent>
                    <ListSubheader><Text small strong info>Views</Text></ListSubheader>
                    {
                        Boolean(props.children.length) && props.children.map((i, index_i) => {
                            if (i.name === viewsPath) {
                                if (i.children.length) {
                                    return i.children.map(((j, index) => {
                                        const routeName = j.name.replace(j.extension, "");
                                        const name = Capitalize(Camelcase(routeName));
                                        return <ListItem button component="a" key={index} href={`${props.name}/${routeName}`}>
                                            <ListItemIcon><ArrowRightAltIcon /></ListItemIcon>
                                            <Text padding={false}>{name}</Text>
                                        </ListItem>
                                    }))
                                } else {
                                    return <ListItem key={index_i}><Text>No views found.</Text></ListItem>
                                }
                            }
                            return false;
                        })
                    }
                </CardContent>
            </Card>
        </Grid>
    )
})`
& .card-action-area {
    padding: ${FBTheme.spacing.unit * 2}px;
}
`

class Prototypes extends Component {
    render() {
        return (
            <div>
                <ResponsiveNavBar
                    zIndex={1210}
                    menuImage={"https://s3-ap-southeast-2.amazonaws.com/formbay-static/design_system_logo.jpg"}
                    menuTitle={"Web Component Library"}
                    menuItems={[
                        { title: "Components", items: ComponentNavItems.children },
                        { title: "Elements", items: ElementNavItems.children },
                        { title: "Prototypes", href: "/prototypes" },
                        { title: "Playground", href: process.env.PLAYGROUND_URL },
                    ]}
                />

                <MainContent>
                    <Grid container spacing={FBTheme.spacing.unit * 2}>
                        {PrototypeNavItems.children.map((i, index) => {
                            return <PrototypeCard key={index} {...i} />
                        })}
                    </Grid>
                </MainContent>
            </div>
        )
    }
}

export default Prototypes