// Create new color palette from hex code
// http://mcg.mbitson.com

import { red, amber, green } from "@material-ui/core/colors";
/* import chroma from "chroma-js"; */

// @manuel
// TODO
// - put this function in helpers

/* const createSwatchesForColor = (hex) => {  
    const shadeMultiplier = 0.3;
    const desaturateMultiplier = 0.55;

    const mainColor = chroma(hex);

    const lightShades = ["400", "300", "200", "100", "50"];
    const darkShades = ["500", "600", "700", "800", "900"];
    const accentShades = ["A100", "A200", "A400", "A700"];
    const swatches = {
        light: {},
        dark: {},
        accent: {}
    };

    accentShades.map((s, index) => {
        let color = mainColor.saturate((index + 1) * shadeMultiplier);
        swatches.accent[s] = color.hex();
    })

    lightShades.map((s, index) => {
        let color = mainColor.desaturate((index + 1) * desaturateMultiplier).brighten(index * shadeMultiplier);
        swatches.light[s] = color.hex();
    })

    darkShades.map((s, index) => {
        let color = mainColor.darken((index + 1) * shadeMultiplier);
        swatches.dark[s] = color.hex();
    })

    return Object.assign(swatches.light, swatches.dark, swatches.accent, {
        contrastText: chroma(hex).luminance() <= 0.5 ? "#fff" : "#000",
        dark: swatches.dark["700"],
        light: swatches.light["200"],
        main: hex,
    });
} */

const Critical = Object.assign(red, {
    contrastText: "#fff",
    main: red[500],
    light: red[200],
    dark: red[700]
})

/* createSwatchesForColor(red["400"]); */

const Warning = Object.assign(amber, {
    contrastText: "#000",
    main: amber[500],
    light: amber[200],
    dark: amber[700]
})

/* createSwatchesForColor(amber["400"]); */

const Positive = Object.assign(green, {
    contrastText: "#fff",
    main: green[500],
    light: green[200],
    dark: green[700]
})

/* createSwatchesForColor(green["400"]); */

const Primary = {
    "500": "#0d4add",
    "50":  "#e2e9fb",
    "100":  "#b6c9f5",
    "200":  "#86a5ee",
    "300":  "#5680e7",
    "400":  "#3165e2",
    "600":   "#0b43d9",
    "700":   "#093ad4",
    "800":   "#0732cf",
    "900":   "#0322c7",
    "A100":    "#eff1ff",
    "A200":    "#bcc3ff",
    "A400":    "#8996ff",
    "A700":    "#6f7fff",
    "contrastText": "#fff",
    "dark": "#093ad4",
    "light": "#5680e7",
    "main": "#0d4add",
  }

  const Secondary = {
    50: '#e0f9f7',
    100: '#b3f0ec',
    200: '#80e6df',
    300: '#4ddcd2',
    400: '#26d5c8',
    500: '#00cdbe',
    600: '#00c8b8',
    700: '#00c1af',
    800: '#00baa7',
    900: '#00ae99',
    A100: '#d8fffa',
    A200: '#a5fff2',
    A400: '#72ffeb',
    A700: '#58ffe8',
    'contrastText': '#fff',
    "dark": "#00c1af",
    "light": "#4ddcd2",
    "main": "#00cdbe",
};

export {
    Primary, Secondary, Critical, Positive, Warning
}