import FBTheme from "../theme/theme";

// @manuel
// TODO
// - type check breakpoint against array of ["xs", "sm", "md", "lg", "xl"]
// - make sure order of breakpoints is correct, smaller breakpoints need to come first...

const lineHeight = (fontSize, multiplier = 1.618) => {
    return Math.floor(fontSize * multiplier);
}

const sizes = {
    hero: {
        ...FBTheme.typography.h1,
        breakpoints: {
            "xs": {
                fontSize: 21,
                fontFamily: FBTheme.typography.fontFamilyMedium
            },
            "md": {
                fontSize: 42,
                fontFamily: FBTheme.typography.fontFamily
            },
        },
        paddingBottom: 12,
        lineHeight: lineHeight(42)
    },
    headline: {
        ...FBTheme.typography.h2,
        breakpoints: {
            "xs": {
                fontSize: 21,
                fontFamily: FBTheme.typography.fontFamilyMedium
            },
            "md": {
                fontSize: 28,
                fontFamily: FBTheme.typography.fontFamilyMedium
            },
        },
        paddingBottom: 12,
        lineHeight: lineHeight(28)
    },
    heading: {
        ...FBTheme.typography.h3,
        breakpoints: {
            "xs": {
                fontSize: 21,
                fontFamily: FBTheme.typography.fontFamilyMedium
            },
            "md": {
                fontSize: 22,
                fontFamily: FBTheme.typography.fontFamilyMedium
            },
        },
        paddingBottom: 12,
        lineHeight: lineHeight(22)
    },
    subheading: {
        ...FBTheme.typography.h4,
        breakpoints: {
            "xs": {
                fontSize: 18,
                fontFamily: FBTheme.typography.fontFamilyMedium
            },
            "md": {
                fontSize: 18,
                fontFamily: FBTheme.typography.fontFamilyMedium
            },
        },
        paddingBottom: 12,
        lineHeight: lineHeight(18)
    },
    large: {
        ...FBTheme.typography.h5,
        breakpoints: {
            "xs": {
                fontSize: 18,
                fontFamily: FBTheme.typography.fontFamily
            },
            "md": {
                fontSize: 18,
                fontFamily: FBTheme.typography.fontFamily
            },
        },
        paddingBottom: 6,
        lineHeight: lineHeight(18)
    },
    standard: {
        ...FBTheme.typography.body1Next,
        breakpoints: {
            "xs": {
                fontSize: 16,
                fontFamily: FBTheme.typography.fontFamily
            },
            "md": {
                fontSize: 16,
                fontFamily: FBTheme.typography.fontFamily
            },
        },
        paddingBottom: 6,
        lineHeight: lineHeight(16)
    },
    small: {
        ...FBTheme.typography.body2Next,
        breakpoints: {
            "xs": {
                fontSize: 14,
                fontFamily: FBTheme.typography.fontFamily
            },
            "md": {
                fontSize: 14,
                fontFamily: FBTheme.typography.fontFamily
            },
        },
        paddingBottom: 6,
        lineHeight: lineHeight(14)
    },
    tiny: {
        ...FBTheme.typography.body2Next,
        breakpoints: {
            "xs": {
                fontSize: 12,
                fontFamily: FBTheme.typography.fontFamily
            },
            "md": {
                fontSize: 12,
                fontFamily: FBTheme.typography.fontFamily
            },
        },
        paddingBottom: 4,
        lineHeight: lineHeight(12)
    },
}

Object.entries(sizes).map(([key, size]) => {
    Object.entries(size.breakpoints).map(([key, breakpoint]) => {
        //const fontSizeInPx = baseFontSize * parseFloat(FBTheme.typography.pxToRem(breakpoint.fontSize));

        return size[FBTheme.breakpoints.up(key)] = {
            fontSize: `${breakpoint.fontSize}px`,
            fontFamily: breakpoint.fontFamily,
        };
    });    

    return size;
})

export default sizes