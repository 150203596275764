import React from "react";
import Button from "./Button";

export const symbols = {
  'Button/Contained/Normal': <Button variant="contained" ></Button>,
  'Button/Contained/Primary': <Button variant="contained" tone="primary"></Button>,
  'Button/Contained/Secondary': <Button variant="contained" tone="secondary"></Button>,
  'Button/Contained/Disabled': <Button variant="contained" disabled></Button>,
  'Button/Outline/Normal': <Button variant="outlined" tone="primary"></Button>,
  'Button/Outline/Secondary': <Button variant="outlined" tone="secondary"></Button>,
  'Button/Outline/Disabled': <Button variant="outlined" disabled></Button>,
}