import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";

import App from './App';

import './index.css';

serviceWorker.register();

const render = AppComponent => {
    return ReactDOM.render(
        <Router>
            <AppComponent />
        </Router>,
        document.getElementById('root')
    );
};

render(App);

// if (module.hot) {
//     module.hot.accept('./App', () => {
//         const NextApp = require('./App').default;
//         render(NextApp);
//     });
// }