// React Libs
import React from 'react';
import { Route } from "react-router-dom";

// Formbay Components & Elements
import ResponsiveNavBar from "../../../react/Components/ResponsiveNavBar/ResponsiveNavBar";
import ComponentDetailPage from "../../../react/helpers/ComponentDetailPage/ComponentDetailPage";

import ComponentsList from './ComponentsList';
import SketchExports from "../SketchExports/SketchExports"

// Navigation Items
import ComponentNavItems from "../../../react/helpers/_components.json";
import ElementNavItems from "../../../react/helpers/_elements.json";

class StyleGuide extends React.Component {
    render() {
        const { match: { path } } = this.props;
        return (
            <React.Fragment>
                <ResponsiveNavBar
                    zIndex={1210}
                    menuImage={"https://s3-ap-southeast-2.amazonaws.com/formbay-static/design_system_logo.jpg"}
                    menuTitle={"Web Component Library"}
                    menuItems={[
                        { title: "Components", items: ComponentNavItems.children },
                        { title: "Elements", items: ElementNavItems.children },
                        { title: "Prototypes", href: "/prototypes" },
                        { title: "Playground", href: process.env.PLAYGROUND_URL },
                    ]}
                />

                <Route exact path={`${path}`} component={ComponentsList} />
                <Route exact path='/components/:name' render={({ match }) => (<ComponentDetailPage name={match.params.name} />)} />
                <Route exact path='/elements/:name' render={({ match }) => (<ComponentDetailPage path={"Elements"} name={match.params.name} />)} />

                <Route exact path="/sketch-exports" component={SketchExports} />
            </React.Fragment>
        );
    }
}
export default StyleGuide; 