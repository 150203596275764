import React from 'react';
import { withRouter } from "react-router";
import PropTypes from 'prop-types';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import OpenInNew from '@material-ui/icons/OpenInNew';
import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import { MuiThemeProvider } from '@material-ui/core';

import Link from "../../Elements/Link/Link";
import Text from "../../Elements/Text/Text";
import FBTheme from '../../../variables/theme/theme';

// helper
import CamelcaseToTitle from "../../helpers/TextHelper/CamelcaseToTitle";
import Capitalize from "../../helpers/TextHelper/Capitalize";

const MenuImage = styled.img`
    width: 48px;
`

const MenuTitle = styled(Text)`
    padding-left: 16px;
`

const drawerWidth = FBTheme.sizes.responsiveNavBar;
const navigationBreakpoint = FBTheme.breakpoints.up('md');

const StyledAppBar = styled(AppBar)`
  && {
    background: ${FBTheme.palette.primary.main};
    margin-left: ${drawerWidth}px;
    ${[navigationBreakpoint]} {
      width: calc(100% - ${drawerWidth}px);
    };
  }
`

const StyledMenuButton = styled(IconButton)`
  && {
    margin-right: 20px;
    ${[navigationBreakpoint]} {
      display: none;
    },
  }
`

const StyledDrawerContainer = styled.nav`
  ${[navigationBreakpoint]} {
    width: ${drawerWidth};
    flex-shrink: 0;
  }
`

const StyledToolbar = styled.div`
  display: flex;
  align-items: center;
  padding: ${FBTheme.spacing.unit}px 24px;
  min-height: 64px;
  box-sizing: border-box;
`

const StyledNavBar = styled.div`
  display: flex;
`

const SecondaryMenuItemText = styled(Text)`
  padding-left: ${FBTheme.spacing.unit * 2}px;  
`

const PrimaryListItem = styled(ListItem)`
  && {
    min-height: ${FBTheme.spacing.unit * 4.5}px;
    padding-bottom: ${FBTheme.spacing.unit * 0.5}px;
    padding-top: ${FBTheme.spacing.unit * 0.5}px;
    padding-left: ${FBTheme.spacing.unit * 3}px;
  }
`

// const SecondaryListItem = styled(ListItem)`
//   && {
//     padding-bottom: ${FBTheme.spacing.unit / 2}px;
//     padding-top: ${FBTheme.spacing.unit / 2}px;
//   }
// `

const PrimaryListItemText = (props) => {
  return props.primary
    ? <ListItemText primary={<Text small padding={false} strong>{props.title}</Text>} />
    : <ListItemText primary={<SecondaryMenuItemText small padding={false}>{props.title}</SecondaryMenuItemText>} />
}

const ListItemLink = (props) => {
  const isExternal = props.href && props.href.match("http(s)?://");

  return (
    <Link to={props.href}>
      <PrimaryListItem button>
        <PrimaryListItemText {...props} />
        {isExternal ? <OpenInNew color="action" /> : null}
      </PrimaryListItem>
    </Link>
  )
}

const StyledCollapse = styled(Collapse)`
  padding-bottom: ${FBTheme.spacing.unit}px;
  padding-top: ${FBTheme.spacing.unit}px;
`

const styles = {
  drawerPaper: {
    width: drawerWidth,
  },
};
class SidebarListItem extends React.Component {
  state = {
    open: false
  }

  handleClick = () => {
    this.setState({ open: !this.state.open });
  }

  render() {
    const menuItem = this.props.menuItem;

    if (menuItem.items && menuItem.items.length) {
      return (
        <div>
          <PrimaryListItem button key={menuItem.title} onClick={this.handleClick}>
            <ListItemText primary={<Text small strong padding={false}>{menuItem.title}</Text>} />
            {this.state.open ? <ExpandLess /> : <ExpandMore />}
          </PrimaryListItem>
          <StyledCollapse in={this.state.open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {menuItem.items.map((subMenuItem, index) => (
                <ListItemLink key={subMenuItem.title} {...subMenuItem} />
              ))}
            </List>
          </StyledCollapse>
        </div>
      )
    } else {
      return (
        <div>
          <ListItemLink key={menuItem.title} {...menuItem} primary="true" />
        </div>
      )
    }
  }
}

class ResponsiveNavBar extends React.Component {
  state = {
    mobileOpen: false,
  };

  handleDrawerToggle = () => {
    this.setState(state => ({ mobileOpen: !state.mobileOpen }));
  };

  render() {
    const { classes, theme, location, zIndex } = this.props;
    const mobileOpen = this.state.mobileOpen;

    const pathnameParts = location.pathname.split("/");
    const pageTitle = Capitalize(CamelcaseToTitle(pathnameParts[pathnameParts.length - 1]));

    const drawer = (
      <div>
        <StyledToolbar>
          <MenuImage src={this.props.menuImage} />
          <MenuTitle strong padding={false}>{this.props.menuTitle}</MenuTitle>
        </StyledToolbar>
        <Divider />
        <List>
          {this.props.menuItems.map((menuItem, index) => (
            <SidebarListItem menuItem={menuItem} key={index} classes={classes} />
          ))}
        </List>
      </div>
    );

    return (
      <MuiThemeProvider theme={FBTheme}>
        <StyledNavBar>
          <StyledAppBar style={{ "zIndex": zIndex }} position="fixed">
            <Toolbar>
              <StyledMenuButton
                color="inherit"
                aria-label="Open drawer"
                onClick={this.handleDrawerToggle}
              >
                <MenuIcon />
              </StyledMenuButton>
              <Typography variant="h6" color="inherit" noWrap>
                {pageTitle}
              </Typography>
            </Toolbar>
          </StyledAppBar>
          <StyledDrawerContainer>
            {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
            <Hidden mdUp implementation="css">
              <Drawer
                container={this.props.container}
                variant="temporary"
                anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                open={mobileOpen}
                onClose={this.handleDrawerToggle}
                classes={{
                  paper: classes.drawerPaper,
                }}
                ModalProps={{
                  container: this.props.container,
                  keepMounted: true, // Better open performance on mobile.
                }}
              >
                {drawer}
              </Drawer>
            </Hidden>
            <Hidden smDown implementation="css">
              <Drawer
                classes={{
                  paper: classes.drawerPaper,
                }}
                variant="permanent"
                open
              >
                {drawer}
              </Drawer>
            </Hidden>
          </StyledDrawerContainer>
        </StyledNavBar>
      </MuiThemeProvider>
    );
  }
}

ResponsiveNavBar.defaultProps = {
  menuItems: [
    { title: "Internal Link", href: "/components" },
    { title: "External Link", href: "https://formbay.com" }
  ],
  menuTitle: "Example Drawer",
}

ResponsiveNavBar.propTypes = {
  container: PropTypes.func,
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  menuItems: PropTypes.array.isRequired,
  menuTitle: PropTypes.string,
  menuImage: PropTypes.string
};

export default withStyles(styles, { withTheme: true })(withRouter(ResponsiveNavBar));