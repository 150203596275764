/**
* @render react
* @name Grid
* @example
* <Grid container spacing={24}> 
*   <Grid item xs>
*       Column 1
*   </Grid>
*   <Grid item xs>
*       Column 2
*   </Grid>
* </Grid>
*   
*/

import Grid from "@material-ui/core/Grid";
import styled from 'styled-components';
/* import FBTheme from '../../../variables/theme/theme' */

// Override global component styles here 
const StyledGrid = styled(Grid)`
   
`;

export default StyledGrid;