import * as Palette from "./colors.js";

// @manuel
// produces a multi-dimensional array
// e.g. [ Color1:[Color1_Shade1, Color1_Shade2, ...], Color2:[Color2_Shade1, Color2_Shade2, ...] ]
export const colors = Object.entries(Palette).map((color) => {
    return {
        [color[0]]: Object.entries(color[1]).map((shade) => {
            return {
                [`${color[0]} ${shade[0]}`]: shade[1]
            }
        })
    };
});