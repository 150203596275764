import React from "react";
import styled from "styled-components";

const StyledCode = styled.div`
  background-color: white;
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
  padding: 20px 40px;
  white-space: pre-wrap;
`

class DemoMissingView extends React.Component {
  state = {
    errorObj: {}
  }

  render() {
    const { name, errorObj } = this.props;

      const code = `
import React from 'react';
import ${name} from "./${name}";

export default class ${name}Demo extends React.Component {
  render() {
    return (
      <${name} />
    );
  }
}
    `

    return (
      <div>
        {errorObj.toString()}
        <p>Example <i>{name}.demo.js</i></p>
        <StyledCode>
          {code}
        </StyledCode>
      </div>
    )
  }
}

export default class LazyLoadModule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      module: null,
      error: null
    };
  }

  // after the initial render, wait for module to load
  async componentDidMount() {
    const { resolve } = this.props;

    try {
      const { default: module } = await resolve();
      this.setState({ module });
    } catch (e) {
      console.log(e);
     this.setState({ errorObj: e, error: DemoMissingView });
    }
  }

  render() {
    const { module, error, errorObj } = this.state;

    if (!module && !error) return <div>Loading module...</div>;
    if (error) return React.createElement(error, { name: this.props.name, errorObj: errorObj });
    if (module) return React.createElement(module, { path: this.props.path, name: this.props.name });
  }
}