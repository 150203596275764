// For default values refer to https://material-ui.com/customization/default-theme/

import { createMuiTheme } from '@material-ui/core/styles';
import * as Palette from "../colors/colors";

// Fonts to choose from
const Fonts = {
    NeueHaasGrotesk: {
        default: '"NeueHaasGrotesk", "Roboto", "Helvetica", "Arial", sans-serif',
        light: "NeueHaasGrotesk-Light",
        medium: "NeueHaasGrotesk-Medium",
        bold: "NeueHaasGrotesk-Bold",
        compact: "NeueHaasGrotesk",
    },
    Atlas: {
        default: '"Atlas", "Roboto", "Helvetica", "Arial", sans-serif',
        light: "Atlas-Light",
        medium: "Atlas-Medium",
        bold: "Atlas-Bold",
        compact: "Atlas",
    },
    Graphik: {
        default: '"Graphik", "Roboto", "Helvetica", "Arial", sans-serif',
        light: "Graphik-Light",
        medium: "Graphik-Medium",
        bold: "Graphik-Bold",
        compact: "Graphik-Compact-Regular",
    },
}

// Define UI font here
// ["Atlas", "NeueHaasGrotesk", "Graphik"]
const UIFont = Fonts["Atlas"];

const borderColor = "rgba(0, 0, 0, 0.23)";
const borderWidth = 2;

const theme = {
    dateFormat: "dd/MM/yyyy",

    palette: {
        primary: Palette.Primary,
        secondary: Palette.Secondary,
        critical: Palette.Critical,
        positive: Palette.Positive,
        warning: Palette.Warning,
        text: {
            critical: Palette.Critical.main,
            positive: Palette.Positive.main,
            warning: Palette.Warning.main,
            secondary: "rgba(0, 0, 0, 0.6)"
        },
        border: {
            input: borderColor
        }
    },
    typography: {
        fontFamily: UIFont.default,
        fontFamilyLight: UIFont.light,
        fontFamilyMedium: UIFont.medium,
        fontFamilyBold: UIFont.bold,
        fontFamilyCompact: UIFont.compact,
        fontSize: 16,
        useNextVariants: true,
        body1Next: {
            fontSize: "1rem",
        },
        body2Next: {
            fontSize: "0.875rem"
        },
    },
    sizes: {
        responsiveNavBar: 240
    },
    shape: {
        borderRadius: 0
    },
    overrides: {
        MuiInputBase: {
            root: {
                fontFamily: UIFont.compact
            }
        },
        MuiFormLabel: {
            root: {
                fontSize: '1rem'
            }
        },
        MuiPrivateNotchedOutline: {
            root: {
                borderWidth: borderWidth,
            },
        },
        MuiOutlinedInput: {
            root: {
                borderRadius: 0,
            }
        },

        MuiButton: {
            root: {
                borderWidth: borderWidth,
                fontFamily: UIFont.medium,
                fontWeight: 500,
                "&$disabled": {
                    "borderWidth": borderWidth
                }
            },
            outlined: {
                borderWidth: borderWidth,
                "&$disabled": {
                    "borderWidth": borderWidth
                }
            },
            outlinedPrimary: {
                borderWidth: borderWidth,
                "&:hover": {
                    borderWidth: borderWidth
                }
            },
            outlinedSecondary: {
                borderWidth: borderWidth,
                "&:hover": {
                    borderWidth: borderWidth
                }
            }
        },
        MuiTypography: {
            h6: {
                lineHeight: "1.25em",
                fontFamily: UIFont.medium
            },
        },
        MuiPickersDay: {
            day: {
                fontFamily: UIFont.medium,
            },
            current: {
                color: Palette.Secondary.main,
            },
        },
    }
};

export default createMuiTheme(theme);
