/* eslint-disable import/first */

// @manuel
// TODO
// - hover styles (generate duplicate classes, like .hover then add those classes in fixSketchRendering function)
// - text formats aka textComponents
// - fix DatePicker border
// - fix placeholders for input elements

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from "styled-components"

/* import chroma from 'chroma-js'; */
import { debounce } from "lodash";

import fixSketchRendering from './fixSketchRendering/fixSketchRendering';

import MainContent from "../../../react/helpers/MainContent/MainContent"
import Text from "../../../react/Elements/Text/Text"
import Grid from "../../../react/Elements/Grid/Grid"

import FBTheme from "../../../variables/theme/theme";

import * as colorsExports0 from "../../../variables/colors/colors.sketch.js"; let colorsExports = [colorsExports0];
const colors = [].concat(...colorsExports.map(x => x.colors || []));

import * as componentExports0 from '../../../react/Components/DatePicker/DatePicker.sketch.js'; import * as componentExports1 from '../../../react/Components/NavBar/NavBar.sketch.js'; import * as componentExports2 from '../../../react/Components/ResponsiveDrawer/ResponsiveDrawer.sketch.js'; import * as componentExports3 from '../../../react/Elements/Button/Button.sketch.js'; import * as componentExports4 from '../../../react/Elements/Link/Link.sketch.js'; import * as componentExports5 from '../../../react/Elements/Text/Text.sketch.js'; let componentExports = [componentExports0, componentExports1, componentExports2, componentExports3, componentExports4, componentExports5];
const textComponents = Object.assign({}, ...componentExports.map(x => x.text || {}));
const symbolComponents = Object.assign({}, ...componentExports.map(x => x.symbols || {}));

const SketchText = ({ name, children, className }) => (
  <div className={className}>
    <div data-sketch-text={name}>
      { children }
    </div>
  </div>
);
SketchText.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};

const PageBlock = ({ children, className, ...restProps }) => {
  return (
    <div className={className} {...restProps}>
      <div>
        {children}
      </div>
    </div>
  );
}

const StyledPageBlock = styled(PageBlock)`
  max-width: 940px;
  margin: 0 auto ${FBTheme.spacing.unit * 4}px auto;
`

const ColorSection = ({ children, name, className }) => {
  return (
    <div>
      <Text heading>{ name }</Text>
      <div className={className}>
        {children}
      </div>
    </div>
  );
}

const StyledColorSection = styled(ColorSection)`
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 20px;
`

const Section = styled.div`
  padding: 20px 0;
`

const SectionHeadline = styled(Text)`
  margin-bottom: ${FBTheme.spacing.unit * 2}px;
`
SectionHeadline.defaultProps = {
  headline: true
}

const StyledSwatch = styled.div`
  border: 1px solid ${FBTheme.palette.divider};
  display: flex;
  height: ${FBTheme.spacing.unit * 6}px;
  padding: ${FBTheme.spacing.unit * 2}px;
  align-items: center;
  position: relative;
  white-space: nowrap;
  width: 100%;
  margin-top: -1px;
`

const StyledColor = styled.div`
  width: 100%;
`

/* const textColor = hex => {
  const vsWhite = chroma.contrast(hex, 'white');
  if (vsWhite > 4) {
    return "#FFF";
  }
  return chroma(hex)
    .darken(2)
    .hex();
}; */

const SketchColor = ({ name, value }) => {
  const styles = {
    color: FBTheme.palette.getContrastText(value)
  }

  return (
    <StyledColor>
      <StyledSymbol name={`Color/${name}`} hideName={true} noMargin={true}>
        <StyledSwatch data-sketch-color={value} style={{ background: value }}>
            <Text padding={false} block strong small style={styles}>{ name }</Text>
        </StyledSwatch>
      </StyledSymbol>
    </StyledColor>
  )
};
SketchColor.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired
};

const SketchSymbolName = styled.div`
  display: ${props => props.hideName ? "none" : "block"};
`

const SketchSymbol = ({ name, children, className, hideName }) => {
  return (
    <div className={className}>
      <SketchSymbolName hideName={hideName}>
        <Text>{ name }</Text>
      </SketchSymbolName>
      <div data-sketch-symbol={name}>
        { children }
      </div>
    </div>
  );
}
SketchSymbol.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};

const StyledSymbol = styled(SketchSymbol)`
  margin-bottom: ${props => props.noMargin ? "0px" : "48px"};
`

export default class SketchExports extends Component {
  constructor() {
    super();
    this.state = { mountIndex: 0 };
  }

  componentDidMount() {
    fixSketchRendering(this.el);

    // Force re-render so 'fixSketchRendering' runs from a blank slate for every viewport size
    window.addEventListener('resize', debounce(this.handleResize), 200);
  }

  componentDidUpdate() {
    fixSketchRendering(this.el);
  }

  componentWillUnmount() {
    document.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    this.setState(state => ({ mountIndex: state.mountIndex + 1 }));
  };

  storeRef = el => {
    this.el = el;
  }

  render() {
    return (
      <MainContent key={this.state.mountIndex} ref={this.storeRef}>
        <StyledPageBlock>
          <Section>
            <SectionHeadline>Text styles</SectionHeadline>
            {
              Object.entries(textComponents).map(([name, element]) => (
                <SketchText key={name} name={name}>{ element }</SketchText>
              ))
            }
            </Section>
        </StyledPageBlock>
       
        <StyledPageBlock>
          <Section>
            <SectionHeadline>Colors</SectionHeadline>
            <Grid container spacing={FBTheme.spacing.unit * 4}>
            {
              colors.map((color) => {
                const key = Object.keys(color)[0];
                const items = color[key];
                return (
                  <Grid item xs={12} sm={4} key={key}>
                    <StyledColorSection name={key}>
                      {items.map(item => {
                        const key = Object.keys(item)[0];
                        const value = item[key];

                        return (
                            <SketchColor key={key} name={key} value={value}>{ value }</SketchColor>
                        )
                      })}
                    </StyledColorSection>
                  </Grid>
                )
              })
            }
            </Grid>
          </Section>
        </StyledPageBlock>

        <StyledPageBlock>
          <Section>
            <SectionHeadline>Symbols</SectionHeadline>
            {
              Object.entries(symbolComponents).map(([name, element]) => (
                <StyledSymbol key={name} name={name}>{ element }</StyledSymbol>
              ))
            }
          </Section>
        </StyledPageBlock>
      </MainContent>
    );
  }
}