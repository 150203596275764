/**
* @render react
* @name DatePicker
* @example
* <CustomDatePicker />
*   
*/

import React, { PureComponent } from 'react';
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import styled from "styled-components";

import DateFnsUtils from '@date-io/date-fns';
import format from 'date-fns/format';
import { MuiPickersUtilsProvider, DatePicker, InlineDatePicker, BasePicker, Calendar } from 'material-ui-pickers';

import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';

import FBTheme from "../../../variables/theme/theme";

import CalendarIcon from "@material-ui/icons/Today";
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

import PickerToolbar from "material-ui-pickers/_shared/PickerToolbar";
import ToolbarButton from "material-ui-pickers/_shared/ToolbarButton";
import { InputLabel } from '@material-ui/core';

import TextField from "../../Elements/TextField/TextField"
import Text from "../../Elements/Text/Text";

// @manuel
// TODO
// - provide format from FBTheme or other constant

class LocalizedUtils extends DateFnsUtils {
    getDatePickerHeaderText(date) {
        return format(date, FBTheme.dateFormat, { locale: this.locale });
    }
}

class StandalonePicker extends PureComponent {
    state = {
        selectedDate: this.props.date,
    };

    handleDateChange = date => {
        this.setState({ selectedDate: date });
        this.props.onChange && this.props.onChange(date);
    };

    render() {
        const {
            className,
            theme,
            classes,
            elevation,
            date,
            toolbar,
            ...restProps
        } = this.props;
        const selectedDate = date || this.state.selectedDate;
        const utils = new DateFnsUtils();

        return (
            <MuiThemeProvider theme={theme}>
                <BasePicker value={selectedDate} onChange={this.handleDateChange}>
                    {({
                        date,
                        handleChange
                    }) => (
                        <div className={className}>
                            <Paper elevation={elevation} style={{ overflow: 'hidden' }}>
                                {toolbar &&
                                    <PickerToolbar>
                                        <ToolbarButton
                                            variant="subtitle1"
                                            label={utils.getYearText(date)}
                                            selected={false}
                                        />

                                        <ToolbarButton
                                            variant="h4"
                                            label={utils.getDatePickerHeaderText(date)}
                                            selected={false}
                                        />
                                    </PickerToolbar>
                                }
                                <Calendar
                                    {...restProps}
                                    date={date}
                                    onChange={this.handleDateChange}
                                    leftArrowIcon={<KeyboardArrowLeftIcon />}
                                    rightArrowIcon={<KeyboardArrowRightIcon />}
                                />
                            </Paper>
                        </div>
                    )}
                </BasePicker>
            </MuiThemeProvider>
        );
    }
}
StandalonePicker.propTypes = {
    classes: PropTypes.object,
    elevation: PropTypes.number,
    theme: PropTypes.object,
}
StandalonePicker.defaultProps = {
    classes: {},
    elevation: 1,
    theme: FBTheme
}

const StyledStandalonePicker = styled(StandalonePicker)`
  /* min-width: 300px; */
`

const CustomInputLabel = (props) => {
    return <StyledInputLabel htmlFor={props.for} className={props.className}>
        <Text strong>{props.children}</Text>
    </StyledInputLabel>
}

const StyledInputLabel = styled(InputLabel)`
  color: ${FBTheme.palette.text.primary};
  display: flex;
  cursor: pointer;
  font-family: ${FBTheme.typography.fontFamilyMedium};
`

const CustomTextField = (props) => {
    const id = `id-${new Date().getTime()}`

    const { label, ...textFieldProps } = props;

    return (
        <div>
            {props.label ? <CustomInputLabel for={id}>{props.label}</CustomInputLabel> : null}
            <TextField id={id} {...textFieldProps} />
        </div>
    )
}

class CustomDatePicker extends PureComponent {
    state = {
        selectedDate: this.props.date,
    };

    handleDateChange = date => {
        this.setState({ selectedDate: date });
        this.props.onChange && this.props.onChange(date);
    };

    render() {
        const {
            standalone,
            inline,
            date,
            theme,
            ...restProps
        } = this.props;

        const selectedDate = date || this.state.selectedDate;

        const datepickerProps = {
            value: selectedDate,
            onChange: this.handleDateChange,
            keyboardIcon: <CalendarIcon />,
            leftArrowIcon: <KeyboardArrowLeftIcon />,
            rightArrowIcon: <KeyboardArrowRightIcon />,
            variant: this.props.variant,
            TextFieldComponent: CustomTextField
        }

        const standaloneDatepickerProps = {
            date: selectedDate,
            onChange: this.handleDateChange,
            theme: theme,
            ...restProps
        }
        
        const DatePickerComponent = () => {
            if (standalone) {
                return <StyledStandalonePicker {...standaloneDatepickerProps} />
            } else if (inline) {
                return <InlineDatePicker {...datepickerProps} />
            } else {
                return <DatePicker {...datepickerProps} />
            }
        }

        return (
            <MuiThemeProvider theme={theme}>
                <MuiPickersUtilsProvider utils={LocalizedUtils}>
                    <DatePickerComponent />
                </MuiPickersUtilsProvider>
            </MuiThemeProvider>
        );
    }
}

CustomDatePicker.propTypes = {
    variant: PropTypes.string,
    placeholder: PropTypes.string,
    format: PropTypes.string,
    date: PropTypes.instanceOf(Date),
    standalone: PropTypes.bool,
    inline: PropTypes.bool,
    label: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    toolbar: PropTypes.bool,
    theme: PropTypes.object
}

CustomDatePicker.defaultProps = {
    date: null,
    format: FBTheme.dateFormat,
    variant: "outlined",
    label: "Date",
    placeholder: "Select a Date",
    standalone: false,
    inline: false,
    toolbar: true,
    theme: FBTheme
}

export default CustomDatePicker